import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header"

// markup
const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404</title>
        <link rel="canonical" href="https://ktp.melonkotak.com/404" />
      </Helmet>
      <Header />
      Not Found
    </>
  )
}

export default NotFoundPage
